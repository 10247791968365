import React, { useEffect, useRef, useState } from "react";
// import axiosInstance from '../../utils/config'
import axios from "axios";
import { url } from "../../utils/url";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function AddPriceModal({ addModal, setAddModal, fetchData }) {
  const [category, setCategory] = useState([]);
  const [categoryId, setCategoryName] = useState("");
  const [price, setPrice] = useState("");
  const fetchCatData = async () => {
    try {
      const { data } = await axios.get(`${url}/size/`);
      setCategory(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchCatData();
  }, []);

  const addFunc = async (e) => {
    e.preventDefault();
    try {
      const data = {
        price,
        size: categoryId,
        flower: addModal?.id,
      };
      await axios.post(`${url}/price/create/`, data);
      setAddModal({ ...addModal, isShow: false });
      fetchData();
    } catch (error) {
      console.log(error);
      toast.error(error?.message);
    }
  };
  return (
    <div className="modal">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header bg-primary py-3">
            <h5 className="modal-title text-white">Добавить цену</h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => setAddModal(false)}
            ></button>
          </div>

          <div className="modal-body">
            <form onSubmit={addFunc}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="mb-3">
                    <input
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                      className="form-control form-control-lg"
                      type="text"
                      placeholder="Цена"
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="mb-3">
                    <select
                      className="form-select"
                      onChange={(e) => setCategoryName(e.target.value)}
                      style={{ height: "50px" }}
                    >
                      <option>категория</option>
                      {category?.map((item) => (
                        <option value={item.id}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-lg-12">
                  <button
                    className="btn-lg btn btn-primary w-100"
                    type="submit"
                  >
                    Добавлять
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddPriceModal;
